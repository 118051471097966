/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-03",
    versionChannel: "nightly",
    buildDate: "2024-07-03T00:05:23.559Z",
    commitHash: "803a8c427ac6449fc4a604351c0b3ed654b5d29e",
};
